<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }} {{ user.role!=='ADMIN' ? ` ຂອງສາຂາ ${user.branch.name}`:"" }}
      </h1>
      <div class="columns">
        <div class="column is-2">
          <b-datepicker
            v-model="fromdate"
            placeholder="ຈາກວັນທີ"
            :locale="this.getDateLocale"
            icon="calendar-today"
            @input="selectFromDate"
          >
          </b-datepicker>
        </div>
        <div class="column is-2">
          <b-datepicker
            v-model="todate"
            placeholder="ຫາວັນທີ"
            :locale="this.getDateLocale"
            icon="calendar-today"
            @input="selectToDate"
          >
          </b-datepicker>
        </div>
        <div class="column is-2">
          <button class="button" @click="get()">
            <b-icon icon="magnify" />
          </button>
        </div>
      </div>
      <card-component class="has-table has-mobile-sort-spaced">
        <b-table
          :loading="isLoading"
          :paginated="perPage < models.length"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          default-sort="amount"
          :data="models"
          :current-page.sync="currentPage"
          :pagination-rounded="true"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
          </b-table-column>
          <b-table-column label="ສະຖານະ" field="status" sortable v-slot="props">
            <shipment-status :status="props.row.status" />
          </b-table-column>
          <b-table-column
            label="ຈຳນວນລາຍການ"
            field="_count.id"
            sortable
            v-slot="props"
          >
            {{ props.row._count.id | formatnumber }}
          </b-table-column>
          <b-table-column
            label="ຈຳນວນເງິນ"
            field="_sum.amount"
            sortable
            v-slot="props"
          >
            {{ props.row._sum.amount | formatnumber }}
          </b-table-column>
          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";
import ShipmentStatus from "../components/ShipmentStatus.vue";
import moment from "moment";
import {get} from "vuex-pathify";
export default {
  name: "ReportShipmentsByStatus",
  components: { CardComponent, EmptyTable, ShipmentStatus },
  computed: {
    user: get("user"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ລາຍງານ ການຂົນສົ່ງ ຕາມວັນທີແຍກຕາມສະຖານະ",
      models: [],
      isShowModal: false,
      isLoading: false,
      fromdate: new Date(),
      todate: new Date(),
      // columns: [
      //   {
      //     label: "ສະຖານະ",
      //     value: () => {
      //       return "ok";
      //     },
      //   },
      //   {
      //     field: "_count.id",
      //     label: "ຈຳນວນລາຍການ",
      //     numeric: true,
      //   },
      //   {
      //     field: "_sum.amount",
      //     label: "ຈຳນວນເງິນ",
      //     numeric: true,
      //   },
      // ],
    };
  },
  mounted() {
    this.get();
  },
  watch:{
    models:function() {
      this.isLoading = false;
    },
  },
  methods: {
    get() {
      this.isLoading=true;
      const fdate = moment(this.fromdate).format("YYYY-MM-DD");
      const tdate = moment(this.todate).format("YYYY-MM-DD");
      axios.get(`report/shipmentsbystatus/${fdate}/${tdate}`).then((r) => {
        this.models = r;
      });
    },
    selectFromDate(e) {
      console.log(e);
    },
    selectToDate(e) {
      console.log(e);
    },
  },
};
</script>